.Insights{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}

.Box{
    margin: 2%;
    padding: 2%;
    border-radius: 25px;

    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
}

.Middle{
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    margin-right: 10px;
}

.Left{
    width: 100%;
    padding-left: 10px;
}

.Progress{
    width: 115px;
    height: 115px;
}

.TextMuted{
    color: var(--color-info-dark);
    font-weight: 600;
}

@media screen and (max-width: 900px) {
    .Insights{
        display: grid;
        grid-template-columns: 1fr;
    }
    .Middle{
        padding-top: 10px;
    }
}
