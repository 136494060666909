@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root{
  --color-primary:#7380ec;
  --color-success:#41f1b6;
  --color-warning:#ffbb55;
  --color-danger:#ff7782;
  --color-white:#fff;
  --color-info-dark:#7d8da1;
  --color-info-light:#dce1eb;
  --color-dark:#363949;
  --color-light:rgba(132,139,200,0.18);
  --color-primary-variant:#111e88;
  --color-dark-variant:#677483;
  --color-background:#f6f6f9;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding: 1.2rem;

  --box-shadow: 0 3rem 4rem var(--color-light);
}

body {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-weight: 800;
  font-size: 1.8rem;
  color: var(--color-dark-variant);
}

h2{
  font-size: 1.4rem;
  color: var(--color-dark);
}

h3{
  font-size: 0.87rem;
  color: var(--color-dark);
}

h4{
  font-size: 0.8rem;
  color: var(--color-dark);
}

h5{
  font-size: 0.77rem;
  color: var(--color-dark);
}