.Navigation{
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
}

.Menu{
    background-color: transparent;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0px;
}

.Title{
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    align-items: center;
}

.Apresentation{
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    align-items: center;
    text-align: center;
}

.Apresentation > p{
    margin-top: 15px;
}

.Finances{
    padding: 5px 5px 5px 10px;
    width: 180px;
    height: 35px;
}

.Logo{
   width: 50px;
   height: 50px;
   margin: 5px 5px 5px 10px;
}

.MenuTitle{
    padding: 10px 10px 0px 10px;
}

@media screen and (max-width: 700px) {
    .Finances{
        width: 100px;
    }

    .Logo{
        width: 30px;
        height: 30px;
    }
}