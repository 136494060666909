.App {
  height: 100vh;
}

.primary{
  color: var(--color-primary);
}

.danger{
  color: var(--color-danger);
}

.success{
  color: var(--color-success);
}

.warning{
  color: var(--color-warning);
}